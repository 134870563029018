import { generateLocale } from './generate';

export default generateLocale([
  'Псевдоним',
  'Никнейм не может быть меньше 3 байт.',
  'Эл. адрес',
  'Пожалуйста, подтвердите адрес вашей электронной почты.',
  'Веб-сайт',
  'Необязательный',
  'Комментарий здесь...',
  'Пока нет комментариев.',
  'Отправить',
  'Like',
  'Cancel like',
  'Отвечать',
  'Отменить ответ',
  'Комментарии',
  'Обновить',
  'Загрузи больше...',
  'Превью',
  'эмодзи',
  'Загрузить изображение',
  'секунд назад',
  'несколько минут назад',
  'несколько часов назад',
  'дней назад',
  'прямо сейчас',
  'Загрузка',
  'Авторизоваться',
  'Выход из системы',
  'Админ',
  'Липкий',
  'Слова',
  'Пожалуйста, введите комментарии от $0 до $1 слов!\nНомер текущего слова: $2',
  'Анонимный',
  'Dwarves',
  'Hobbits',
  'Ents',
  'Wizards',
  'Elves',
  'Maiar',
  'GIF',
  'Поиск GIF',
  'Персональные данные',
  'Одобренный',
  'Ожидающий',
  'Спам',
  'Нелипкий',
]);
