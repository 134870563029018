import { generateLocale } from './generate';

export default generateLocale([
  '暱稱',
  '暱稱不能少於3個字元',
  '郵箱',
  '請填寫正確的郵件地址',
  '網址',
  '可選',
  '歡迎評論',
  '來發評論吧~',
  '提交',
  '喜歡',
  '取消喜歡',
  '回覆',
  '取消回覆',
  '評論',
  '刷新',
  '載入更多...',
  '預覽',
  '表情',
  '上傳圖片',
  '秒前',
  '分鐘前',
  '小時前',
  '天前',
  '剛剛',
  '正在上傳',
  '登錄',
  '退出',
  '博主',
  '置頂',
  '字',
  '評論字數應在 $0 到 $1 字之間！\n當前字數：$2',
  '匿名',
  '潛水',
  '冒泡',
  '吐槽',
  '活躍',
  '話癆',
  '傳說',
  '表情包',
  '搜索表情包',
  '個人資料',
  '通過',
  '待審核',
  '垃圾',
  '取消置頂',
]);
